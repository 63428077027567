// @jsx jsx

import { jsx } from 'theme-ui';
import React, { ReactNode } from 'react';
import {
  Button as RebassButton,
  ButtonProps as RebassButtonProps,
  Link as RebassLink
} from 'rebass';
import { graphql } from 'gatsby';

export interface LinkButtonProps {
  id?: string;
  text?: string;
  link?: string;
  openLinkInNewTab?: boolean;
  type?: 'link' | 'button';
  variant?: 'primary' | 'secondary';
  children?: ReactNode;
}

export const linkButtonQuery = graphql`
  fragment Links on ContentfulLinkButton {
    id
    text
    link
    openLinkInNewTab
    type
    variant
  }
`;

const Button = ({
  text,
  link,
  variant,
  openLinkInNewTab,
  children,
  ...rest
}: LinkButtonProps) => {
  return (
    <RebassButton
      {...(link && { as: 'a', href: link })}
      variant={variant}
      {...(openLinkInNewTab && { target: '_blank', rel: 'noreferrer' })}
      {...rest}
    >
      {text}
      {children}
    </RebassButton>
  );
};

const Link = ({
  text,
  link,
  variant,
  openLinkInNewTab,
  children,
  ...rest
}: LinkButtonProps) => {
  return (
    <RebassLink
      variant={`link.${variant}`}
      href={link}
      {...(openLinkInNewTab && { target: '_blank', rel: 'noreferrer' })}
      {...rest}
    >
      {text}
      {children}
    </RebassLink>
  );
};

const LinkButton = ({
  text,
  link,
  type,
  variant,
  openLinkInNewTab,
  children,
  ...rest
}: LinkButtonProps) => {
  let linkOrButton = null;
  switch (type && type.toLowerCase()) {
    case 'button':
      linkOrButton = (
        <Button {...{ text, link, variant, openLinkInNewTab, ...rest }}>
          {children}
        </Button>
      );
      break;

    default:
      linkOrButton = (
        <Link {...{ text, link, variant, openLinkInNewTab, ...rest }}>
          {children}
        </Link>
      );
      break;
  }
  return linkOrButton;
};

export default LinkButton;
