import React, { ReactNode } from 'react';
import { Styled } from 'theme-ui';
import { ModalProvider } from 'react-modal-hook';

import '../global.scss';
import Navbar from './navbar/navbar';
import Footer from './footer/footer';

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Styled.root>
      <ModalProvider>
        <Navbar />
        {children}
        <Footer />
      </ModalProvider>
    </Styled.root>
  );
};

export default Layout;
