import { useStaticQuery, graphql } from 'gatsby';

export const useLogo = () => {
  const {
    blackLogo: {
      childImageSharp: { blackLogo }
    },
    whiteLogo: {
      childImageSharp: { whiteLogo }
    }
  } = useStaticQuery(
    graphql`
      query LogoQuery {
        blackLogo: file(
          relativePath: { eq: "images/wize-solutions-logo-black-fs8.png" }
        ) {
          childImageSharp {
            blackLogo: fixed(height: 45) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        whiteLogo: file(
          relativePath: { eq: "images/wize-solutions-logo-white-fs8.png" }
        ) {
          childImageSharp {
            whiteLogo: fixed(height: 45) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `
  );
  return { blackLogo, whiteLogo };
};
