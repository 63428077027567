// @jsx jsx

import { jsx } from 'theme-ui';
import React from 'react';
import { Styled } from 'theme-ui';
import { Flex, Box, Link } from 'rebass';
import Image from 'gatsby-image';
import AiOutlineMenu from '@meronex/icons/ai/AiOutlineMenu';
import { IconContext } from '@meronex/icons';
import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';

import './navbar.scss';
import MainContainer from '../container/container';
import { useLogo } from '../../hooks/useLogo';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import LinkButton from '../link-button/link-button';

interface NavbarProps {
  background?: string;
  color?: string;
}

const NavLinks = ({ mobile, ...rest }) => {
  return (
    <Styled.root>
      <Flex
        alignItems="center"
        className="navbar"
        {...(!mobile && { sx: { display: ['none', 'none', 'initial'] } })}
        {...(mobile && {
          flexDirection: 'column',
          sx: {
            width: '100vw',
            py: 3,
            textAlign: 'center',
            display: ['', '', 'none', 'none']
          }
        })}
        {...rest}
      >
        <Link as="div" variant="link.nav" className="dropdown-trigger">
          Services
          <div className="dropdown-menu">
            <ul>
              <li>
                <a href="/services/pallet-rack-installation">
                  Rack Installation
                </a>
              </li>
              <li>
                <a href="/services/dock-and-door-installation">
                  Dock &amp; Door Installation
                </a>
              </li>
              <li>
                <a href="/services/specialty-installation">
                  Specialty Installation
                </a>
              </li>
              <li>
                <a href="/services/permit-running">Permit Running</a>
              </li>
            </ul>
          </div>
        </Link>
        <Link variant="link.nav" href="/about">
          About
        </Link>
        <Link variant="link.nav" href="/careers">
          Careers
        </Link>
        <LinkButton type="button" link="/contact" variant="secondary">
          Contact us
        </LinkButton>
      </Flex>
    </Styled.root>
  );
};

const Navbar = ({
  background = 'background',
  color = 'text',
  ...rest
}: NavbarProps) => {
  const { title } = useSiteMetadata();
  const { whiteLogo, blackLogo } = useLogo();
  const [showNavModal, hideNavModal] = useModal(() => (
    <ReactModal
      isOpen
      onRequestClose={hideNavModal}
      appElement={document.getElementById('___gatsby')}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          display: 'flex'
        },
        content: {
          position: 'relative',
          top: '82px',
          left: '0',
          right: '0',
          bottom: 'unset',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '0'
        }
      }}
    >
      <NavLinks mobile={true} />
    </ReactModal>
  ));

  return (
    <Box color={color} bg={background}>
      <MainContainer py="3">
        <Flex width="100%" alignItems="center">
          <Link href="/">
            <Image
              fixed={background === 'dark' ? whiteLogo : blackLogo}
              alt={`${title} logo`}
              loading="eager"
              fadeIn={false}
            />
          </Link>
          <Box mx="auto" />
          <NavLinks mobile={false} />
          <LinkButton
            type="button"
            variant="secondary"
            px={3}
            sx={{ display: ['initial', 'initial', 'none'] }}
            onClick={showNavModal}
          >
            <IconContext.Provider
              value={{ size: '1rem', style: { verticalAlign: 'middle' } }}
            >
              <AiOutlineMenu />
            </IconContext.Provider>
          </LinkButton>
        </Flex>
      </MainContainer>
    </Box>
  );
};

export default Navbar;
