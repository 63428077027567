import React, { ReactNode } from 'react';
import { Flex } from 'rebass';

interface ContainerProps {
  children?: ReactNode;
  pt?: number[] | number | string;
  pb?: number[] | number | string;
  pl?: number[] | number | string;
  pr?: number[] | number | string;
  fullWidth?: boolean;
}

const MainContainer = ({
  children,
  pt,
  pb,
  pl = 4,
  pr = 4,
  fullWidth,
  ...rest
}: ContainerProps) => {
  return (
    <Flex
      maxWidth={fullWidth ? '100%' : '1200px'}
      width="100%"
      flexWrap="wrap"
      mx={[0, 'auto']}
      pt={pt}
      pl={pl}
      pb={pb}
      pr={pr}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default MainContainer;
