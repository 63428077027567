import { useStaticQuery, graphql } from 'gatsby';

export interface ISiteMetadata {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      siteUrl: string;
      image: string;
    };
  };
}

export const useSiteMetadata = () => {
  const {
    site: {
      siteMetadata: { title, description, siteUrl, image }
    }
  }: ISiteMetadata = useStaticQuery(
    graphql`
      query siteMetadataQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );
  return { title, description, siteUrl, image };
};
