// @jsx jsx

import { jsx } from 'theme-ui';
import React from 'react';
import { Flex, Box, Link, Text, Heading } from 'rebass';
import Image from 'gatsby-image';

import MainContainer from '../container/container';
import { useLogo } from '../../hooks/useLogo';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

interface FooterProps {
  background?: string;
  color?: string;
}

const Footer = ({
  background = 'dark',
  color = 'background',
  ...rest
}: FooterProps) => {
  const { title } = useSiteMetadata();
  const { whiteLogo, blackLogo } = useLogo();

  return (
    <Box color={color} bg={background} pt={5} as="footer">
      <MainContainer>
        <Flex
          width="100%"
          alignItems="flex-start"
          flexDirection={['column', 'column', 'row']}
        >
          <Box>
            <Link href="/">
              <Image
                fixed={background === 'dark' ? whiteLogo : blackLogo}
                alt={`${title} logo`}
              />
            </Link>
            <Text as="p" my="4">
              2500 S Decker Lake Blvd #30
              <br />
              West Valley City, Utah 84119
            </Text>
            <Link
              href="tel:(801)966-0210"
              variant="link.secondary"
              my="1"
              display="inline-block"
            >
              (801) 906-0303
            </Link>
            <br />
            <Link
              href="mailto:sales@wizesolutions.com"
              rel="noreferrer"
              target="_blank"
              variant="link.secondary"
              my="1"
              display="inline-block"
            >
              sales@wizesolutions.com
            </Link>
          </Box>
          <Box mx="auto" />
          <Flex mt={[4, 4, 0]} flexWrap="wrap">
            <Box as="section" maxWidth="220px" mr="5">
              <Heading as="h4" variant="footerHeader" color={color}>
                <Text variant="link.footer">Services</Text>
              </Heading>
              <Text as="ul" variant="ul.footer">
                <Text as="li" variant="li.footer">
                  <Link
                    variant="link.footer"
                    href="/services/pallet-rack-installation"
                  >
                    Rack Installation
                  </Link>
                </Text>
                <Text as="li" variant="li.footer">
                  <Link
                    variant="link.footer"
                    href="/services/dock-and-door-installation"
                  >
                    Dock &amp; Door
                  </Link>
                </Text>
                <Text as="li" variant="li.footer">
                  <Link
                    variant="link.footer"
                    href="/services/specialty-installation"
                  >
                    Specialty Installation
                  </Link>
                </Text>
                <Text as="li" variant="li.footer">
                  <Link variant="link.footer" href="/services/permit-running">
                    Permit Running
                  </Link>
                </Text>
              </Text>
            </Box>
            <Box as="section" width={['100%', 'auto', 'auto']}>
              <Heading
                as="h4"
                variant="footerHeader"
                color={color}
                mt={[4, 0, 0]}
              >
                <Text variant="link.footer">Company</Text>
              </Heading>
              <Text as="ul" variant="ul.footer">
                <Text as="li" variant="li.footer">
                  <Link variant="link.footer" href="/about">
                    About
                  </Link>
                </Text>
                <Text as="li" variant="li.footer">
                  <Link variant="link.footer" href="/privacy-policy">
                    Privacy Policy
                  </Link>
                </Text>
                <Text as="li" variant="li.footer">
                  <Link variant="link.footer" href="/terms-and-conditions">
                    Terms &amp; Conditions
                  </Link>
                </Text>
                <Text as="li" variant="li.footer">
                  <Link variant="link.footer" href="/careers">
                    Careers
                  </Link>
                </Text>
                {/*                 <Text as="li" variant="li.footer">
                  <Link variant="link.footer" href="/blog">
                    Blog
                  </Link>
                </Text> */}
                <Text as="li" variant="li.footer">
                  <Link
                    variant="link.footer"
                    href="https://wizesolutions.bamboohr.com/"
                  >
                    Employee Login
                  </Link>
                </Text>
                <Text as="li" variant="li.footer">
                  <Link variant="link.footer" href="/contact">
                    Contact Us
                  </Link>
                </Text>
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Box as="hr" variant="hr.secondary" />
        <Flex width="100%">
          <Box>
            <Text>
              &copy; {title} {new Date().getFullYear()}
            </Text>
          </Box>
          <Box alignSelf="flex-end" ml="auto"></Box>
        </Flex>
        <Flex
          mt="5"
          mb={[5, 4, 3]}
          alignItems="center"
          justifyContent={['flex-start', 'center', 'center']}
          width="100%"
        >
          <Link
            href="https://pegadigital.com"
            target="_blank"
            variant="link.footer"
            fontSize="0"
          >
            Web Design by <strong>Pega Digital</strong>
          </Link>
        </Flex>
      </MainContainer>
    </Box>
  );
};

export default Footer;
